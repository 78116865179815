
<style >
	::-webkit-scrollbar {
		width: 0px;
	}
</style>
<template>
	<div style="background:#060B1B;min-width:1920px">
		<div style="width:1920px;height:1080px;margin:0 auto;overflow: hidden;">
			<bigData :tips="tips" />
		</div>
	</div>
</template>
<script>
	import bigData from "./index/bigData.vue";
	import request from "../../plugins/axios";
	export default {
		components: {
			bigData,
		},
		data() {
			return {
				tips: "",
			};
		},
		created() {
			// let _that = this;
			// request({
			// 	url: "admin/Screen/get_now",
			// 	data: {},
			// }).then((ret) => {
			// 	_that.tips = ret.data;
			// });
		},
	};
</script>